import React from 'react';
import { ExtensionComponentProps, PiletApi } from '@sharefiledev/sharefile-appshell';
import { Spin } from 'antd';
import { FeatureFlag } from '../FeatureFlags';
import {
	checkRolesAndPreferences,
	isVdr,
	RolesAndProvisioningRequirements,
} from '../provisioning';
import { workflowsPiletExtensionSlots } from '../sdk';
import { t } from '../util';
import { StartClientFlowAction } from './StartClientFlowAction/StartClientFlowAction';
import { StartFlowActionExtensionProps } from './StartFlowActionExtension';
import { StartProspectFlowAction } from './StartProspectFlowAction/StartProspectFlowAction';
import { ContextualActionComponent, TriggerSourceType } from './types';

const getRequirementsForStartProspectFlowAction = (app: PiletApi) => {
	const requirements: RolesAndProvisioningRequirements = {
		requiredRoles: ['Employee', 'CanSendDocumentsForSignature'],
		requiredASTs: ['EnableAutomatedWorkflows'],
	};

	if (isVdr(app)) {
		requirements.requiredLaunchDarklyFeatureFlags = [
			FeatureFlag.EnableAutomatedWorkflowsVDR,
			FeatureFlag.EnableAgreementsFeatureVDR,
		];
	}

	return requirements;
};

const getRequirementsForStartClientFlowAction = (app: PiletApi) => {
	const requirements: RolesAndProvisioningRequirements = {
		requiredRoles: ['Employee', 'CanSendDocumentsForSignature'],
		requiredASTs: ['EnableAutomatedWorkflows', 'EnableClientAgreements'],
		requiredLaunchDarklyFeatureFlags: [FeatureFlag.EnableAgreementsForExistingClients],
	};

	if (isVdr(app)) {
		requirements.requiredLaunchDarklyFeatureFlags = [
			FeatureFlag.EnableAgreementsForExistingClients,
			FeatureFlag.EnableAutomatedWorkflowsVDR,
			FeatureFlag.EnableAgreementsFeatureVDR,
		];
	}

	return requirements;
};

export type ExtensionGenerator = {
	actionComponent: ContextualActionComponent;
	actionName: string;
	triggerSourceType: TriggerSourceType;
	/**
	 * Specify how your domain-specific props will map to the generic "StartFlowAction" component's "inputs" prop, see {@link StartFlowAction} .
	 * @param props
	 * @returns
	 */
	resolveInputs: (props: ExtensionComponentProps<any>) => any[];
	resolveParams?: (props: ExtensionComponentProps<any>) => any;
	isAvailable: (app: PiletApi) => boolean;
};

// Helper object to declaratively generate and configure different extensions that contextually kick-off workflows
const startFlowContextualExtensions: {
	[extensionName: string]: ExtensionGenerator;
} = {
	[workflowsPiletExtensionSlots.prospectiveClientsListContextualActionSlot]: {
		actionComponent: StartProspectFlowAction,
		triggerSourceType: 'sharefile.prospect',
		// eslint-disable-next-line @citrite/no-global-translations
		actionName: t('workflows-pilet:titles.send_agreements'),
		resolveInputs: (props: ExtensionComponentProps<any>) => {
			return props.params.prospects;
		},
		resolveParams: (props: ExtensionComponentProps<any>) => {
			return props.params;
		},
		isAvailable: app =>
			checkRolesAndPreferences(app, getRequirementsForStartProspectFlowAction(app)),
	},
	[workflowsPiletExtensionSlots.clientsListContextualActionSlot]: {
		actionComponent: StartClientFlowAction,
		triggerSourceType: 'sharefile.client',
		// eslint-disable-next-line @citrite/no-global-translations
		actionName: t('workflows-pilet:titles.send_agreements'),
		resolveInputs: (props: ExtensionComponentProps<any>) => {
			return props.params.contacts;
		},
		isAvailable: app =>
			checkRolesAndPreferences(app, getRequirementsForStartClientFlowAction(app)),
	},
};

const LazyShareFlowAction = React.lazy(() => import('./StartFlowActionExtension'));

const AsyncShareFlowAction: React.FC<StartFlowActionExtensionProps> = props => {
	return (
		<React.Suspense fallback={<Spin />}>
			<LazyShareFlowAction {...props} />
		</React.Suspense>
	);
};

export function configureStartFlowContextualExtensions(app: PiletApi) {
	let extensionName: keyof typeof startFlowContextualExtensions;
	for (extensionName in startFlowContextualExtensions) {
		const extensionGenerator = startFlowContextualExtensions[extensionName];
		app.registerExtension(extensionName, props => {
			return (
				<AsyncShareFlowAction
					extensionGenerator={extensionGenerator}
					piletApi={app}
					{...props}
				/>
			);
		});
	}
}
