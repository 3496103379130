import React, { lazy, Suspense } from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { BlockParams } from '@sharefiledev/solution-view-engine';
import { Spin } from 'antd';
import { LaunchFlowBlockParams } from '../sdk';

export type LaunchFlowBlockProps = LaunchFlowBlockParams &
	BlockParams & { piletApi: PiletApi };

const LazyLaunchFlowButton = lazy(() => import('./LaunchFlowButton'));
export const LaunchFlowButton = (props: LaunchFlowBlockProps) => (
	<Suspense fallback={<Spin />}>
		<LazyLaunchFlowButton {...props} />
	</Suspense>
);
