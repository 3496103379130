import { Resource, ResourceInfo } from '@citrite/domino-core-sdk';
import * as Builder from '@sharefile/domino-builder';
import { getResolvedResourceName } from '../../helpers';
import { dominoApiClient } from '../dominoApiClient';
import { withFetchResourceOverride } from './helpers';

export interface ResourceRegistry extends Builder.ResourceRegistryClient {
	fetchResources(type: string): Promise<ResourceInfo[]>;
	fetchResource(fqn: string): Promise<Resource>;
}

export type FetchResourceFn = (fqn: string) => Promise<Resource>;

export function getResourceRegistryClient(override?: FetchResourceFn): ResourceRegistry {
	const fetchResource = withFetchResourceOverride(
		async (fqn: string): Promise<Resource> => {
			let resourceName = getResolvedResourceName(fqn);

			return await dominoApiClient.resources.get({
				namespace: resourceName.namespace,
				_package: resourceName.package,
				name: resourceName.name,
				version: resourceName.version,
			});
		},
		override
	);

	const fetchResources = async (type: string): Promise<ResourceInfo[]> =>
		await dominoApiClient.resources.listResources({
			type,
		});

	return {
		fetchResources,
		fetchResource,
	};
}

export enum ResourceType {
	Action = 'action',
	Trigger = 'trigger',
	Type = 'type',
}
