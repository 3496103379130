import React from 'react';
import { Spin } from 'antd';
import { ContextualWorkflowListProps } from '../sdk';

const LazyContextualWorkflowList = React.lazy(() => import('./ContextualWorkflowList'));

export const AsyncContextualWorkflowList: React.FC<
	ContextualWorkflowListProps
> = props => {
	return (
		<React.Suspense fallback={<Spin />}>
			<LazyContextualWorkflowList {...props} />
		</React.Suspense>
	);
};
