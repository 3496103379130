export enum FeatureFlag {
	EnableStartFlowContextualActions = 'enable-start-flow-contextual-actions', // Enables first use case PCWA-602 (Send email from client list) - short lived flag till one time rollout
	EnableAgreementsForExistingClients = 'enable-agreements-for-existing-clients', // Accelerated agreements for existing clients
	ShowTabsInPreviewTemplate = 'show-tabs-in-preview-template', // shows new tabs view (SFCC-17) in preview template
	EnableBFFOnWorkflowsPilet = 'enable-bff-on-workflows-pilet', // configures Domino API client to utilize BFF
	EnableProductLedGrowth = 'enable-product-led-growth', // Enable PLG banners and modal popups
	EnableMarketplace = 'enable-marketplace', // enable marketplace feature in left nav (rubicon)
	EnableSendEmailForWorkflowDesigner = 'enable-send-email-for-workflow-designer', // enables sendEmail action in designer (PCWA-1557)
	EnableWorkflowsPiletForEU = 'enable-workflows-pilet-for-eu', // enables usage in EU environment
	EnableAutomatedWorkflowsVDR = 'enable-automated-workflows-vdr', // enables automated workflows for VDR account types (PCWA-1580)
	EnableAgreementsFeatureVDR = 'enable-agreements-feature-vdr', // enables agreements feature for VDR account types (PCWA-1796)
}
