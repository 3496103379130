import {
	CalendarIcon,
	EmptyFolderIcon,
	EnvelopeIcon,
	FolderOutlineIcon,
	IconProps,
	NumbersInputIcon,
	PersonIcon,
	SendForSignatureActionIcon,
	ShareActionIcon,
	TextBlockIcon,
	TextInputIcon,
} from '@sharefiledev/flow-web';
import {
	CommentBalloonIcon,
	ConnectionIcon,
	DoubleCheckMarkIcon,
	EmployeeIcon,
	FileIcon,
	FileTemplateIcon,
	ManualTriggerIcon,
	ObjectIcon,
	ProspectIcon,
	QuickbooksIcon,
	SalesforceIcon,
	UrlLinkIcon,
	VariableIcon,
	WorkflowsNavIcon,
} from '../icons';
import { NullIcon } from './NullIcon';

export interface IconStoreEntry {
	name: string;
	defaultIcon?: React.FC<IconProps>;
	emptyStateIcon?: React.FC<IconProps>;
}

export const iconStore: Record<string, IconStoreEntry> = {
	Application: {
		name: 'Application',
		defaultIcon: NullIcon,
	},
	Client: {
		name: 'Client',
		defaultIcon: PersonIcon,
	},
	Comment: {
		name: 'Comment',
		defaultIcon: CommentBalloonIcon,
	},
	Connection: {
		name: 'Connection',
		defaultIcon: ConnectionIcon,
	},
	connection: {
		name: 'connection',
		defaultIcon: ConnectionIcon,
	},
	connection_v2: {
		name: 'connection_v2',
		defaultIcon: ConnectionIcon,
	},
	Contact: {
		name: 'Contact',
		defaultIcon: PersonIcon,
	},
	Customer: {
		name: 'Customer',
		defaultIcon: PersonIcon,
	},
	Date: {
		name: 'Date',
		defaultIcon: CalendarIcon,
	},
	Email: {
		name: 'Email',
		defaultIcon: EnvelopeIcon,
	},
	Employee: {
		name: 'Employee',
		defaultIcon: EmployeeIcon,
	},
	File: {
		name: 'File',
		defaultIcon: FileIcon,
	},
	Folder: {
		name: 'Folder',
		defaultIcon: FolderOutlineIcon,
		emptyStateIcon: EmptyFolderIcon,
	},
	FolderInfo: {
		name: 'Folder',
		defaultIcon: FolderOutlineIcon,
		emptyStateIcon: EmptyFolderIcon,
	},
	Invoice: {
		name: 'Invoice',
		defaultIcon: FileIcon,
	},
	Manual: {
		name: 'Manual',
		defaultIcon: ManualTriggerIcon,
	},
	null: {
		name: 'null',
		defaultIcon: NullIcon,
	},
	number: {
		name: 'number',
		defaultIcon: NumbersInputIcon,
	},
	Object: {
		name: 'Object',
		defaultIcon: ObjectIcon,
	},
	ObjectPicker: {
		name: 'ObjectPicker',
		defaultIcon: PersonIcon,
	},
	Organization: {
		name: 'Organization',
		defaultIcon: NullIcon,
	},
	Prospect: {
		name: 'Prospect',
		defaultIcon: ProspectIcon,
	},
	Quickbooks: {
		name: 'Quickbooks',
		defaultIcon: QuickbooksIcon,
	},
	QuoteObjectPicker: {
		name: 'QuoteObjectPicker',
		defaultIcon: FileIcon,
	},
	Salesforce: {
		name: 'Salesforce',
		defaultIcon: SalesforceIcon,
	},
	Share: {
		name: 'Share',
		defaultIcon: ShareActionIcon,
	},
	Signature: {
		name: 'Signature',
		defaultIcon: SendForSignatureActionIcon,
	},
	Select: {
		name: 'Select',
		defaultIcon: NullIcon,
	},
	string: {
		name: 'string',
		defaultIcon: TextBlockIcon,
	},
	Task: {
		name: 'Task',
		defaultIcon: DoubleCheckMarkIcon,
	},
	Template: {
		name: 'Template',
		defaultIcon: FileTemplateIcon,
	},
	Text: {
		name: 'Text',
		defaultIcon: TextInputIcon,
	},
	Url: {
		name: 'Url',
		defaultIcon: UrlLinkIcon,
	},
	User: {
		name: 'User',
		defaultIcon: PersonIcon,
	},
	Variable: {
		name: 'Variable',
		defaultIcon: VariableIcon,
	},
	Workflow: {
		name: 'Workflow',
		defaultIcon: WorkflowsNavIcon,
	},
	Workspace: {
		name: 'Workspace',
		defaultIcon: NullIcon,
	},
};
